<template>
  <div class="container">
    <div class="head">
      <div class="search_box" style="margin-bottom: 20px;">
        <div class="search_main">
          <div class="status">
            <div>申请日期</div>
            <div>
              <el-date-picker
                v-model="datatime"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="datebtn"
                :clearable="true"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status">
            <div>店铺名称</div>
            <div>
              <el-input
                placeholder="请输入店铺名称"
                v-model="queryinfo.storeName"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status">
            <div>结算状态</div>
            <div class="min">
               <el-select   v-model="queryinfo.status" placeholder="请选择">
                   <el-option
                     v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                   </el-option>
                 </el-select>
            </div>
          </div>
        </div>
        <div class="search_main" >
          <div class="status">
            <div>筛选</div>
            <div class="min">
               <el-select   v-model="queryinfo.type" placeholder="请选择">
                   <el-option
                     v-for="item in typeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                   </el-option>
                 </el-select>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status1" style="display: flex">
            <div class="demandBtn" @click="search">
              <img src="../../assets/images/search.png" alt="" /> 查询
            </div>
            <div class="resetBtn" @click="reset">
              <img src="../../assets/images/reset.png" alt="" />重置
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-card class="body">

      <div class="text">
        <!-- 查询 -->
        <el-table :data="tableData" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }" style="width: 100%;font-family: PingFangRoutine;">
          <el-table-column prop="billDate" label="日期" width="200">
						<template slot-scope="scope">
							<span>
								{{billDate(scope.row.createTime)}}
							</span>
						</template>
					</el-table-column>
          <el-table-column prop="freshStore.name" label="店铺名称" width="200"></el-table-column>
          <el-table-column prop="orderAmount" label="订单金额 (元)" width="200">
          <template slot-scope="scope">
            {{scope.row.totalAmount/100}}
          </template>
          </el-table-column>
          <el-table-column prop="storeIncome" label="商家结算 (元)">
            <template slot-scope="scope">
              {{scope.row.storeAmount/100}}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span v-show="scope.row.orderStatus==0">待付款</span>
              <span v-show="scope.row.orderStatus==1">已取消</span>
                  <span v-show="scope.row.orderStatus==2">代发货</span>
                  <span v-show="scope.row.orderStatus==3">已发货</span>
                  <span v-show="scope.row.orderStatus==4">已完成</span>
                  <span v-show="scope.row.orderStatus==5">已关闭</span>
                  <span v-show="scope.row.orderStatus==6">无效订单</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <span @click="route(scope.row.id)" style="color:blue">查看订单</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>

    </el-card>
 <div style="height: 10px"></div>
      <div class="footer">
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
       </div>
  </div>
</template>

<script>
import { orderRefundList } from "@/api/order";
import { settlementDetail } from "@/api/finance.js"
import "../../font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        pageNo: 1, //当前页数
        endDate:null, //结束日期
        startDate:null, //开始日期
        pageSize: 10, //每页记录数
        status: null, //商户结算状态
        storeName: null, //店铺名称
        type: null, //结算类型
      },
      datatime: [], //时间
      total: null, //总条数
      activeName: "first", //tabs切换
      tableData: [], //表格数据
      memberId:'',
      options:[{value:null,label:'全部'},{value:1,label:'结算中'},{value:2,label:'已结算'}],
      typeList:[{value:null,label:'全部'},{value:0,label:'消费'},{value:1,label:'退款'},{value:2,label:'对冲'},{value:3,label:'提现'}]
    };
  },
  created() {
    this.list();
  },
  methods: {
    //表格样式
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
		billDate(date){//截取日期年月日
			return date.slice(0,10)
		},
    // 列表
    list() {
      settlementDetail(this.queryinfo).then(res=>{
        this.total = res.data.total * 1
        this.total == null?0:this.total
        this.tableData=res.data.records
      })

    },
    //确认确定按钮
    datebtn() {
      if (!this.datatime) { // 如果被清空，手动给它赋值为[]
        this.datatime = []
      }
      if (typeof this.datatime !== 'string' && this.datatime.length !== 0) {
        this.startDate=this.datatime[0]
        this.endDate=this.datatime[1]
        this.queryinfo.startDate = this.datatime[0],
        this.queryinfo.endDate = this.datatime[1]
      }
    },
    // 查询
    search() {
      if (!this.datatime) { // 如果被清空，手动给它赋值为[]
        this.datatime = []
        this.queryinfo.startDate = ''
        this.queryinfo.endDate = ''
      }
      this.total=0
      this.queryinfo.startDate = this.datatime[0];
      this.queryinfo.endDate = this.datatime[1];
      // this.queryinfo.currPage=1
      this.list();
    },
    //重置
    reset() {
      this.total=0
      this.datatime=[],
      this.queryinfo.startDate=''
      this.queryinfo.endDate=''
      this.queryinfo.storeName="",
      this.queryinfo.afterNo="",
      this.queryinfo.type="",
      this.queryinfo.status="",
      this.list()
    },
    // 点击跳转详情
    route(id){
      this.$router.push({path:'/capital/orderDetail',query:{id:id}})
    },

    // 分页
    handleCurrentChange(val) {
      this.queryinfo.pageNo = val;
      this.list();
    },
    // 查看详情
    examine(val) {
      this.$router.push({
        path:'/order/refund/detail',
        query:{id: val.id,
         orderNo:val.orderNo}
      })
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/common.less";
/deep/ .min .el-input__inner {
    width: 100px;
}
.box-card {
  width: 100%;
}
.head{
  margin-bottom: 10px;
  background-color: white;
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/deep/ .tabs {
  margin-left: 20px;
  margin-top: 30px;
}
/deep/ .el-table th.el-table__cell > .cell {
  text-align: center;
}
/deep/ .el-table__cell {
  text-align: center;
}
/deep/ .el-table td.el-table__cell div{
  text-align: center !important;

}
</style>
